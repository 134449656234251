<template>
  <div id="aboutus">
    <Header nav="2"></Header>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <!-- 公司简介 -->
      <div class="company">
        <div class="en"><span class="En">C</span><span>OMPANY</span></div>
        <div class="ch">{{ pageList[0].title }}</div>
        <div class="pageContent1">
          <div class="pageTitle pageTitle1">{{ pageList[0].name }}</div>
          <div class="pageText">{{ pageList[0].content }}</div>
        </div>
        <div class="pageimg1">
          <img class="pageImg1" :src="pageList[0].image" />
        </div>
      </div>

      <!-- 核心团队 -->
      <div class="team">
        <div class="en"><span class="En">C</span><span>ORE TEAM</span></div>
        <div class="ch">{{ pageList[1].title }}</div>
        <div class="pageText pageText2">{{ pageList[1].content }}</div>
        <div class="pageName">
          <div class="pageContent2">
            <img class="pageImg2" :src="pageList[1].image" />
            <div class="pageHtml">
              <div class="nameMsg" v-if="nameActive == 0">
                <div class="nameTitle">{{ pageList[2].title }}</div>
                <div class="nameText">{{ pageList[2].content }}</div>
              </div>
              <div class="nameMsg" v-if="nameActive == 1">
                <div class="nameTitle">{{ pageList[3].title }}</div>
                <div class="nameText">{{ pageList[3].content }}</div>
              </div>
              <div class="nameMsg" v-if="nameActive == 2">
                <div class="nameTitle">{{ pageList[4].title }}</div>
                <div class="nameText">{{ pageList[4].content }}</div>
              </div>
            </div>
          </div>
          <div class="name">
            <div
              class="nameItem"
              :class="nameActive == index ? 'nameActive' : ''"
              v-for="(item, index) in coreTeam"
              :key="index"
              @click="nameActive = index"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- 公司背景 -->
      <div class="background">
        <div class="en"><span class="En">B</span><span>ACKGROUND</span></div>
        <div class="ch">{{ pageList[5].title }}</div>
        <div class="pageTitle pageTitle3">{{ pageList[5].name }}</div>
        <div class="pageContent3">
          <div class="img">
            <img class="pageImg" :src="pageList[5].image" />
            <div class="companyMsg">
              <img class="logo" :src="logoImg.logo_url" />
              <div class="companytxt">{{ pageList5.content }}</div>
              <div
                class="more"
                @click="goto(pageList5.link, pageList5.link_name)"
              >
                <div>进一步了解</div>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
          <div class="companyName">
            <div class="companys">
              <div
                class="companyItem"
                v-for="(item, index) in textList"
                :key="index"
                :class="companyActive == index ? 'companyItemActive' : ''"
                @click="changeCompany(index)"
              >
                <i></i>
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading } from "vant";
export default {
  components: {
    Header,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      pageList: [],
      coreTeam: [], //核心团队人名
      textList: [], //公司名
      nameActive: 0, //人名高亮
      companyActive: 0, //公司名高亮
      pageList5: {},
      logoImg: {},
    };
  },
  mounted() {
    // 关于我们-轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 10 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
          }, 300);
        }
      });

    this.$axios
      .post("https://api1.pyua.net/index/content", { type: 10 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.pageList = res.data.arr;
          this.pageList5 = this.pageList[5];
          this.coreTeam = res.data.arr.slice(2, 5);
          let textList = [];
          res.data.arr.forEach((item, index) => {
            if (index >= 5) {
              textList.push(item.link_name);
            }
          });
          this.textList = textList;
        }
      });

    this.$axios.post("https://api1.pyua.net/index/logo").then((response) => {
      // console.log(response.data.arr);
      if (response && response.code == 1) {
        this.logoImg = response.data.arr[0];
        // 把logo保存到本地，切换的时候从本地拿数据，减少请求次数
        localStorage.setItem("logoList", JSON.stringify(response.data.arr));
      }
    });
  },
  methods: {
    changeCompany(index) {
      this.companyActive = index;
      this.pageList5 = this.pageList[index + 5];
      let logoList = JSON.parse(localStorage.getItem("logoList"));
      this.logoImg = logoList[index];
    },

    // 点击进一步了解
    goto(url, name) {
      if (name == "无邪生物") {
        this.$router.push("/");
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
#aboutus {
  box-sizing: border-box;
}
.banner {
  margin-top: 2.75rem;
  background-color: #f4f8f6;
  img {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.en {
  font-family: "DIN";
  margin-top: 2.9375rem;
  margin-bottom: 0.3125rem;
  color: #d5e1db;
  font-size: 18px;
  line-height: 1;
  .En {
    font-size: 28px;
    line-height: 1;
  }
}
.ch {
  line-height: 1;
  color: #206c47;
  font-weight: 700;
  font-size: 18px;
}

.pageTitle {
  color: #206c47;
  font-size: 16px;
}
.pageText {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
}

.company {
  background-color: #f4f8f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pageContent1 {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pageTitle1 {
      margin: 1.875rem 0 1.5rem 0;
    }
  }
  .pageimg1 {
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 3.0625rem;
    .pageImg1 {
      width: 100%;
    }
  }
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  .pageText2 {
    margin: 1.875rem 0;
  }
  .pageName {
    margin-bottom: 3.0625rem;
  }
  .pageContent2 {
    position: relative;
    font-size: 0;
    .pageImg2 {
      width: 100%;
      height: 100%;
    }
    .pageHtml {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // .nameMsg {
      //   // width: 80%;
      // }
      .nameTitle {
        font-size: 0.875rem;
      }
      .nameText {
        font-size: 0.75rem;
        white-space: pre-wrap;
        line-height: 1.5;
        margin-top: 0.625rem;
      }
    }
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5rem;
    line-height: 1;
    .nameItem {
      flex: 1;
      height: 100%;
      background-color: #f4f8f6;
      border-right: 1px solid #ccc;
      color: #ccc;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-last-child(1) {
        border-right: none;
      }
    }
    .nameActive {
      background-color: #206c47;
      color: #fff;
    }
  }
}

// 公司背景
.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f8f6;
  .pageTitle3 {
    margin: 1.875rem 0;
    font-size: 0.875rem;
  }
  .pageContent3 {
    .img {
      position: relative;
      font-size: 0;
      .pageImg {
        width: 100%;
      }
      .companyMsg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 2.375rem;
        text-align: justify;
        .logo {
          height: 2.625rem;
        }
        .companytxt {
          margin: 1.75rem 0 0.875rem 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: 0.75rem;
          color: #333;
          line-height: 1.5;
        }
        .more {
          display: flex;
          align-items: center;
          background-color: #206c47;
          color: #fff;
          font-size: 0.75rem;
          padding: 0.5rem 1rem;
        }
      }
    }
    .companyName {
      width: 100%;
      height: 5.34375rem;
      padding: 0 1rem;
      margin-top: 1.875rem;
      margin-bottom: 3.0625rem;
      .companys {
        width: 100%;
        height: 100%;
        display: flex;
      }
      .companyItem {
        border-top: 1px solid #eee;
        line-height: 1;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        color: #999999;
        font-size: 0.875rem;
        position: relative;
        i {
          position: absolute;
          top: 0;
          content: "";
          width: 100%;
          height: 3px;
          background-color: #eee;
        }
        div {
          margin-top: 1.375rem;
          width: 1.1875rem;
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .companyItemActive {
        font-weight: 700;
        color: #206c47;
        position: relative;
        font-size: 1rem;
        i {
          position: absolute;
          top: 0;
          content: "";
          width: 100%;
          height: 3px;
          background-color: #206c47;
          animation: add 0.3s linear;
        }
        @keyframes add {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>