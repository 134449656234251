<template>
  <div id="footer">
    <div class="phoneTop">
      <div class="companyTitle2">{{ footerList.web_name }}</div>
      <div class="company">
        <div class="companyLeft">
          <img class="img1" src="../assets/icon1.png" />
        </div>
        <div>：{{ footerList.web_address }}</div>
      </div>
      <div class="company">
        <div class="companyLeft">
          <img class="img2" src="../assets/icon2.png" />
        </div>
        <div>：{{ footerList.web_mobile }}</div>
      </div>
      <div class="company">
        <div class="companyLeft">
          <img class="img3" src="../assets/icon3.png" />
        </div>
        <div>：{{ footerList.web_email }}</div>
      </div>
    </div>

    <div class="phoneBottom">
      <div class="copyright" @click="goto(footerList.icp_link)">
        {{ footerList.icp_message }}
      </div>
      <div class="copyright">
        {{ footerList.web_copyright }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerList: {},
    };
  },
  mounted() {
    this.$axios.post("https://api1.pyua.net/index/web").then((response) => {
      // console.log(response.data);
      if (response && response.code == 1) {
        this.footerList = response.data;
        // 把地址保存到本地，跳转的时候从本地拿数据，减少请求次数
        localStorage.setItem("url", JSON.stringify(response.data.wuxie_url));
      }
    });
  },
  methods: {
    goto(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
#footer {
  // padding: 1.875rem 1rem;
  background-color: #206c47;
  color: #fff;
  font-size: 0.75rem;
}
.phoneTop {
  padding: 0 1rem;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1;
  color: #e6e4e4;
  border-bottom: 1px solid #999;
  .companyTitle2 {
    font-size: 0.875rem;
    padding: 1.875rem 0 1.5rem 0;
    color: #fff;
  }
  .company {
    margin-bottom: 1.125rem;
    font-size: 0.75rem;
    color: #ccc;
    display: flex;
    align-items: center;
    &:nth-last-child(1) {
      margin-bottom: 1.5rem;
    }
    .companyLeft {
      width: 1.0875rem;
      height: 1.0875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .img1 {
        width: 1.0875rem;
        height: 1.0875rem;
      }
      .img2 {
        width: 1.0875rem;
        height: 0.83125rem;
      }
      .img3 {
        width: 0.7rem;
        height: 0.95625rem;
      }
    }
  }
}
.phoneBottom {
  padding: 1.5rem 0;
  .copyright {
    text-align: center;
    color: #ccc;
    line-height: 1;
    font-size: 0.75rem;
    &:nth-child(1) {
      margin-bottom: 1.125rem;
    }
  }
}
</style>